<!--房屋管理  -->
<template>
  <div class="">
    <ax-table
      ref="roleTable"
      :show-search="true"
      :searchForm="searchForm"
      @add="add"
      :columns="columns"
      :toolActions="toolActions"
      :dataSourceApi="api.queryList"
      :dataSourceParams="dataSourceParams"
      :clickable="false"
      :showToolBar="true"
      @pop-confirm="popConfirm"
      @action-column-click="actionColumnClick"
      :scroll="{ y: '55vh', x: '80vw' }"
    >
      <div slot="ownerName" slot-scope="{ record }">
        <div v-if="record.ownerName === null || record.ownerName === ''">
          <span>--</span>
        </div>
        <div v-else>
          <span>{{ record.ownerName }}</span>
        </div>
      </div>

      <div slot="phone" slot-scope="{ record }">
        <div v-if="record.phone === null || record.phone === ''">
          <span>--</span>
        </div>
        <div v-else>
          <span>{{ record.phone }}</span>
        </div>
      </div>
      <div slot="livingStatus" slot-scope="{ record }">
        <div class="plotting" v-if="record.livingStatus === '0'">
          <span>已住</span>
        </div>
        <div class="plotting" v-else>
          <span>闲置</span>
        </div>
      </div>
    </ax-table>

    <!-- 新增跳转弹窗 -->
    <el-dialog
      title="添加房屋信息"
      :visible.sync="dialogAdd"
      width="1000px"
      :z-index="12"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <addunit
        @close="dialogAdd = false"
        :buildingId="buildingId"
        ref="add"
        @refreshTable="refreshTable"
    /></el-dialog>
    <!-- 编辑 -->
    <editunit ref="edit" @refreshTable="refreshTable"></editunit>
  </div>
</template>
  <script>
import api from "../api";
import addunit from "../Common/AddHouse.vue";
import editunit from "../Common/editunit.vue";

import { isEmpty, initGridFormData } from "../../../../../../common/tools";
const Residential = [
  { label: "已住", value: "0" },
  { label: "闲置", value: "1" },
];
const searchForm = [
  {
    label: "单元",
    type: "inputSearch",
    model: "unitName",
    options: { placeholder: "单元" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "楼层",
    type: "inputSearch",
    model: "floorName",
    options: { placeholder: "楼层" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "门牌号",
    type: "inputSearch",
    model: "doorPlate",
    options: { placeholder: "门牌号" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "居住现状",
    type: "select",
    model: "livingStatus",
    options: { options: Residential, placeholder: "请选择居住现状" },
    formItem: {},
    col: { span: 6 },
  },
];
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    editunit,
    addunit,
  },
  data() {
    //这里存放数据
    return {
      api,
      editShow: false,
      dialogAdd: false,
      id: this.$route.query.recordId,
      buildingId: this.$route.query.recordId,
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 120,
      }),
      searchActions: [
        { name: "search", text: "查询" },
        { name: "reset", text: "重置" },
      ],
      toolActions: [{ name: "add", text: "新增房屋" }],
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "单元",
            dataIndex: "unitName",
            ellipsis: true,
            width: 120,
          },
          {
            title: "楼层",
            dataIndex: "floorName",
            ellipsis: true,
            width: 120,
          },
          {
            title: "门牌号",
            dataIndex: "doorPlate",
            ellipsis: true,
            width: 120,
          },
          {
            title: "房主",
            dataIndex: "ownerName",
            ellipsis: false,
            width: 120,
          },
          {
            title: "联系方式",
            dataIndex: "phone",
            ellipsis: false,
            width: 120,
          },
          {
            title: "居住现状",
            dataIndex: "livingStatus",
            ellipsis: false,
            width: 120,
          },
        ],
        true,
        {
          width: 200,
          actions: [
            {
              props: (record) => {
                return {
                  text: "房屋可视化",
                  type: "#ecb96d",
                  name: "visualization",
                  link: true,
                  popDisabled: true,
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "编辑",
                  type: "#2A5CFF",
                  name: "edit",
                  link: true,
                  popDisabled: true,
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  type: "#F95A5A",
                  name: "del",
                  link: true,
                  popDisabled: false,
                  title: "确认删除？",
                };
              },
            },
          ],
        }
      ),
      dataSourceParams: { buildingId: this.$route.query.recordId },
      visible: false,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //方法集合
  methods: {
    // 新建项目弹窗
    add() {
      this.dialogAdd = true;
    },
    // 点击删除操作的api
    async popConfirm({ record }) {
      const res = await api.deleteHouse(record.id);
      if (res.status === 200) {
        this.$message.success("删除成功");
      } else {
        this.$message.error("删除失败");
      }
      // 刷新列表数据
      this.$refs.roleTable.getDataSource();
    },

    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        //编辑楼栋
        case "edit":
          this.$refs.edit.openModal(record);
          break;
        //删除
        case "del":
          break;
        //房屋可视化
        case "visualization":
          this.$router.push({
            path: "/views/communityGovernance/housingList/buildingList/Housvvisualization/index.vue",
            query: {
              title: "房屋可视化",
              recordId: record.id,
              id: this.id,
            },
          });
          break;
      }
    },

    //新增刷新列表数据
    refreshTable() {
      this.$refs.roleTable.getDataSource();
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
  <style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
</style>